<template>
    <div class="song-container">
    <div class="song" @mouseenter="showPlayButton" @mouseleave="hidePlayButton">
        <!-- <pre>{{song}}</pre> -->
        <span class="spacer-play" style=""><PlayIcon v-if="showButton" @click.native="play(song)"  class="play-button" /></span>
        <span class="spacer-number">{{index+1}}</span>
        <span class="title"><div v-html="song.name" /></span>
        <div class="artist"> 
            <music-icon class="icon" /> <span>{{song.music}},</span> 
            <lyrics-icon class="icon" /> <span>{{song.lyrics}},</span> 
            <vocals-icon class="icon" /> <span>{{ song.vocals}}</span> 

        </div>
    
    </div>
        
    </div>
</template>

<script>
import LyricsIcon from '../icons/LyricsIcon.vue';
import MusicIcon from '../icons/MusicIcon.vue';
import VocalsIcon from '../icons/VocalsIcon.vue';
import PlayIcon from '../icons/PlayIcon.vue';

export default {
  inject: ['play'],
  components: { MusicIcon, LyricsIcon, VocalsIcon, PlayIcon },
        // inject: ['baseURL'],
        props: ['song', 'index'],
        mounted() {
            // let url = new URL(this.baseURL + '/song/' + this.id);

            // console.log(url);
            
            // fetch(url)
            // .then(response => response.json())
            // .then(response => {
            //     this.song = response;
            // })
            // console.log(this);
        


        },

        data() {
            return {
                showButton: false,
                // song: {
                //     // title: '',
                //     // music: '',
                //     // lyrics: '',
                //     // vocals: '',
                //     // won: false,
                //     // lyricsheet: '',
                //     // is_local: false,
                //     // url: '',
                //     // streamurl: '',
                //     // local_url: '',

                // }
            }
        },
        methods: {
            showPlayButton: function() {
                this.showButton = true;  
            },
            hidePlayButton: function() {
                this.showButton = false;
            },
            // play: this.play, 
        },

        name: 'RoundSong',
        computed: {

        }
    }
</script>

<style>
    :root {
    --tracks-bg: #121212;
    --tracks-hover-bg: #302c2c; 
    }

    .play-button {
        @apply h-5 text-gray-100 hover:text-pink-600 cursor-pointer;
    }
    .song-container {
        background: var(--tracks-bg);
        border-bottom: 1px solid rgb(55, 65, 81);
        /* transition: 0.3s ease; */
    }
    .song-container:hover {
        background: var(--tracks-hover-bg);
        @apply rounded;

    }

    .spacer-play {
        @apply px-2 w-6 h-5 ml-1 flex flex-row items-center -mr-1;
    }
    .spacer-number {
        @apply px-1 w-5 text-gray-300 flex flex-row items-center justify-center;
    }
    .song {
     
        @apply flex flex-row items-center text-gray-50 h-12;
    }
    .title {
        width: 40%;
        @apply mr-2 text-left;
    }
    .artist {
        @apply flex flex-row items-center text-gray-50;
    }
    .icon {
        @apply mx-1 h-4;
    }

</style>