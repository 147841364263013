<template>
    <div class="round">
        <div class="round-header">
            <div class="round-square">{{round.number}}</div>
            <div class="round-metadata">
                <div class="round-theme"><div v-html="round.theme" /></div>
                <div class="round-contributors"><span class="contributor-title">Contributors: </span> {{contributors.join(', ')}}</div>
                
            </div>
            
        </div>
        <div v-if="round.songs.length">
            <RoundSong :song="song" :index="index" :key="index" v-for="(song,index) in round.songs" />
        </div>
        


    </div>
</template>

<script>
    import RoundSong from '@/components/RoundSong.vue';
     
    export default {
        inject: ['baseURL'],
        name: 'Round',
        props: ['id'],
        data() {
            return {
                round: {
                    songs: []
                },
                contributors: []
            }
        },
        methods: {
            //https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
            // /* Randomize array in-place using Durstenfeld shuffle algorithm */
            shuffle: function(array) {
                for (var i = array.length - 1; i > 0; i--) {
                    var j = Math.floor(Math.random() * (i + 1));
                    var temp = array[i];
                    array[i] = array[j];
                    array[j] = temp;
                }
            },
            getContributors: function() {
                // this.round.songs.forEach(song => {
                //     this.contributors.add(song.music)
                // });]
                let s = new Set();
                // for(let i=0;i<this.round.songs.length;i++) {       
                //     s.add(this.round.songs[i].music.toLowerCase());
                //     s.add(this.round.songs[i].lyrics.toLowerCase());
                //     s.add(this.round.songs[i].vocals.toLowerCase());   
                // }

                this.round.songs.forEach(song => {
                    let {music,lyrics,vocals} = song;
                    let split_music = false;
                    let split_lyrics = false;
                    let split_vocals = false;
                    let delimiters = ',+&'.split('');
                    
                    //this could be more efficient if done on backend
                    //gets expensive quickly
                    for(let i = 0; i < delimiters.length; i++) {
                        if(music.indexOf(delimiters[i]) != -1 && !split_music) {
                            music = music.split(delimiters[i]);
                            split_music = true;
        
                        }
                        if(lyrics.indexOf(delimiters[i]) != -1 && !split_lyrics) {
                            lyrics = lyrics.split(delimiters[i]);
                            split_lyrics = true;
                        }
                        if(vocals.indexOf(delimiters[i]) != -1 && !split_vocals) {
                            vocals = vocals.split(delimiters[i]);
                            split_vocals = true;
                        }
                    }

                    if(split_music) {
                        music.forEach(m => {
                            if(m.trim() != '')
                                s.add(m.trim().toLowerCase())
                        });
                    }
                    else {
                        if(music.trim() != '')
                            s.add(music.trim().toLowerCase())
                    }
                    if(split_lyrics) {
                        lyrics.forEach(l => {
                            if(l.trim() != '')
                                s.add(l.trim().toLowerCase())
                        });
                    }
                    else {
                        if(lyrics.trim() != '')
                            s.add(lyrics.trim().toLowerCase())
                    }
                    if(split_vocals) {
                        vocals.forEach(v => {
                            if(v.trim() != '')
                                s.add(v.trim().toLowerCase())
                        });
                    }
                    else {
                        if(vocals.trim() != '')
                            s.add(vocals.trim().toLowerCase())
                    }

          
                })

                this.contributors = [...s];
            }

        },
        computed: {

        },
        components: {
            RoundSong
        },

        mounted() {
            let slug = '/round/' + this.id;
            let url = new URL(this.baseURL + slug);
            
            fetch(url)
            .then(response => response.json())
            .then(response => {
                this.shuffle(response.songs); 
                return response;
            })
            .then(response => {
                this.round = response;
                this.getContributors()
            })
            .catch(error => console.log(error))
            
        }
    }
</script>

<style scoped>
.round-header {
    @apply text-6xl text-gray-100 font-bold flex flex-row items-center mb-2;
        
}
.round {
    width: 960px;
    @apply py-2;
}

.round-square {
    @apply h-20 w-20 bg-black rounded flex flex-row items-center justify-center font-bold text-gray-50 text-lg mr-6;
    min-height: 5rem;
    min-width: 5rem;
}

.round-contributors {
    @apply text-gray-400 text-xs mt-2;
}
.contributor-title {
    @apply  text-gray-300 font-bold;
  
}
</style>