<template>
    <div id="footer" v-show="song.name">
        <div id="audio-player">
           
            
        </div> 
         <div v-if="loading < 100" class="h-16 loader" :style="'width:'+loading+'%;'">&nbsp;</div>
      
        <div class="controls-credits"> 
            <div class="left"> 
                <PlayIcon class="control-icon" @click.native="WSplay" v-if="this.wavesurfer != null && !this.wavesurfer.isPlaying()" />
                <PauseIcon class="control-icon" @click.native="WSpause" v-if="this.wavesurfer != null && this.wavesurfer.isPlaying()" />
                <span class="now-playing" v-if="this.song.name">Now playing: <span class="song-title"><span v-html="this.song.name" /></span> - <span class="song-contributors">{{this.song.music}}, {{this.song.lyrics}}, {{this.song.vocals}} </span> 
                </span>
             </div>
            
            <div class="right"> <span class="current-time">{{this.current_position}}</span> <span class="time-spacer">/</span> <span class="total-time">{{this.song_length }}</span></div>
        </div>
    </div>
</template>

<script>

    import WaveSurfer from "wavesurfer.js";
    import PlayIcon from "@/icons/PlayIcon.vue";
    import PauseIcon from "@/icons/PauseIcon.vue";

    export default {
        name: 'AudioPlayer',
        inject: ['client_id'],
        props: ['song'],
        data() {
            return {
                title: '',
                artists: '',
                round:'',
                song_length: '',
                current_position: '',
    
                wavesurfer: null,
                song_check_interval: null,
                loading: 0,
                // song: this.song
            }
        },
        components: {
            PlayIcon,
            PauseIcon,
            

        },
        watch: {
            song: function(nv, ov) {
                // this.wavesurfer.load()
                console.log('new val',nv)
                this.WSload(nv);
            },

        },
        methods: {
            updateLoader: function(val) {
                // console.log(val)
                this.loading = val;
            },
            updateTime: function() {

              this.current_position = this.formatTime(this.wavesurfer.getCurrentTime());
            
            },

            formatTime: function(timeInSeconds) {
              
                // Hours, minutes and seconds
                var hrs = ~~(timeInSeconds / 3600);
                var mins = ~~((timeInSeconds % 3600) / 60);
                var secs = ~~timeInSeconds % 60;

                // Output like "1:01" or "4:03:59" or "123:03:59"
                var ret = "";
                if (hrs > 0) {
                    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
                }
                ret += "" + mins + ":" + (secs < 10 ? "0" : "");
                ret += "" + secs;
                return ret;
               

            },
            createWaveSurfer() {
                console.log('createWaveSurfer');
                
                this.wavesurfer = WaveSurfer.create({
                    container: "#audio-player",
                    barWidth: 2,
                    barGap: 0,
                    progressColor: "#ec4899",
                    waveColor: "#8b5cf6",
                    normalize: true,
                    mediaControls: true,
                    responsive: true,
                    height: 72,
                    pixelRatio: 1
                    
                });
                
                // console.log('loading song data', this.songData)
                // this.wavesurfer.load(this.song.songData.u);

                if(this.song?.songData?.url) {
                    let url = this.songData.is_local ? this.songData.streamurl + this.client_id : this.songData.local_url;
                    console.log('loading url: ' + url);
            
                    this.wavesurfer.load(url);
                    
                    this.wavesurfer.on('ready', this.WSplay);


                    
                     
                }

                    let ws = this.wavesurfer;
                    this.wavesurfer.on('loading', this.updateLoader)
                    this.wavesurfer.on('audioprocess', this.updateTime);
                    this.wavesurfer.on('seek', this.updateTime);


                // let url = "https://api.soundcloud.com/tracks/259786801/stream" + this.client_id;
                // this.wavesurfer.load(url);

                // this.wavesurfer.on('ready', this.WSplay);
                 

            },

            WSload: function(updatedVal = null) {

                console.log('updatedval from function', updatedVal);

                if(updatedVal) {
                    console.log('updated value')
                    let url = updatedVal.is_local ? updatedVal.local_url: updatedVal.streamurl + this.client_id;
                    console.log('loading url: ' + url);
            
                    this.wavesurfer.load(url);

                    
                    this.wavesurfer.on('loading', this.updateLoader)

                    
                    

                    this.wavesurfer.on('ready', this.WSplay);
                    // this.wavesurfer.cu
                }
                // if(this.song?.songData?.url) {
                //     let url = this.songData.is_local ? this.songData.streamurl + this.client_id : this.songData.local_url;
                //     console.log('loading url: ' + url);
            
                //     this.wavesurfer.load(url);

                //     this.wavesurfer.on('ready', this.WSplay);
                // }
            
            },


            WSplay: function() {
                if(!this.wavesurfer.isPlaying()) {
                    console.log('playing');
                    this.song_length = this.formatTime(this.wavesurfer.getDuration());
                    this.wavesurfer.play();
                    
                }
            },
            WSpause: function() {
                if(this.wavesurfer.isPlaying()){
                    this.wavesurfer.pause();
                }
            }

      

        },
        computed: {
            // songData: function() {
            //     // this.loadSurfer();
            //     // this.createWaveSurfer();
            //     console.log('songData updated')
            //     if(this.$store.state.songData.url != null) this.WSload();
            //     // this.WSload();
  
                
            //     return this.$store.state.songData;
            // }
            // current_time: function() {
              
            //         return this.wavesurfer.getCurrentTime();

            //     return null;
            // }
        
        },
        async mounted() {
            console.log('Mounted');
 
            if (!this.wavesurfer) this.createWaveSurfer();
        },
    }
</script>

<style scoped>
#footer {
   /* border: 2px solid red; */
   @apply w-full fixed bottom-0 left-0 z-20;
   @apply  pt-2;
   
   border-top: 2px solid #272727;

   background: #171717;
           animation-name: fadeIn;
    animation-duration: 1s;
   /* border-top: 2px solid red;  */
}
#audio-player {
    border-bottom: 2px solid rgba(39, 39, 39, 0.205);

}

.now-playing {
   @apply text-gray-400 ml-1; 
}

.song-title {
    @apply font-bold text-gray-100;
}
.song-contributors {
    @apply font-bold text-gray-200;
}

.controls-credits {
    @apply py-0.5 flex flex-row items-center pl-1 justify-between;
    background: #151515;
}
.control-icon {
    @apply h-5 w-5 text-purple-600 hover:text-pink-600 cursor-pointer;
}
canvas {

}

.left {
    @apply flex flex-row items-center;
}
.right {
    @apply  mr-1;
}

.current-time {
    @apply text-gray-500;
}
.time-spacer {
    @apply text-gray-500;
}
.total-time {
    @apply text-gray-100 font-bold;
}

.loader {
    transition: 0.3s ease;
    @apply bg-purple-600;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(200%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>