import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    songData: {
      lyricsheet: {}
    }
  },
  mutations: {
    // updateSongData(state, song) {
    //   console.log('mutate song update', song);
    //   setTimeout(() => { console.log(state)}, 2000);
    //   state['songData'] = song;
    // }

    setSongData(state, song) {
      // console.log('set songData')}
      state['songData'] = song;
       
    }
  
  },
  actions: {
    setSongData({ commit, state }, song) {
      console.log('dispatch song update', song);
      // state.songData = {};
      // state['songData'] = song;
    
      commit('setSongData', song);
      setTimeout(() => { console.log(state)}, 2000);
    }

  },
  modules: {
  }
})
