<template>
    <div class="lyrics-sidebar" v-if="song.name">
        <pre v-html="song.lyricsheet" class="lyrics-container" />

    </div>
</template>

<script>
    export default {
        name: 'LyricsSidebar',
        computed: {
            song: function() {
                return this.$store.state.songData;
            }
        }
    }
</script>

<style scoped>
.lyrics-sidebar {
    @apply fixed w-64  top-0 right-0 z-10 h-full text-sm;
    background: #0c0c0c;
    border-left: 2px solid #131313;
    animation-name: fadeInLeft;
    animation-duration: 1s;
}

.lyrics-container {
    @apply text-gray-200 px-4 mt-24 mx-auto w-48;
    /* border: 1px solid green; */
    max-height: 75%;
    overflow-y: auto;

}

pre {
    word-break: break-word;
    @apply px-2 mr-2;
    white-space: pre-line;
    font-family: 'Open Sans', sans-serif;
    


}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(+200%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
</style>