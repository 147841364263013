<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->

    <div class="container">

      <!-- <button @click="testPlay">Play Sound</button> -->
    

      <!-- <Round :id="i" :key="i" v-for="i in sampleArray" /> -->
      <Round :id="93" />
      <LyricsSidebar />
      <AudioPlayer :song="songData" />
      
      <!-- <vue-sound
  :title="songData.title ? songData.title : 'Waiting for track...'"
  title-link=""
  :details="songData.music ? songData.music+', '+songData.lyrics+', '+songData.vocals: ''"
  details-link=""
  :file="songData.id ? (songData.is_local ? songData.local_url: songData.streamurl + client_id): ''"
></vue-sound> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import Song from '@/components/Song.vue';
// import Vue from 'vue'
// import VueSound from 'vue-sound'
import Round from '@/components/Round.vue';
import AudioPlayer from '@/components/AudioPlayer.vue';
import LyricsSidebar from '@/components/LyricsSidebar.vue';


export default {
  provide() {
    return {
      baseURL: this.baseURL,
      play: this.play, 
      client_id: this.client_id,
      // songData: this.songData,
      
    }
  },
  data() {
      return {
        baseURL: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_DEV_URL : process.env.VUE_APP_PROD_URL,  

        sampleArray:Array.from({length: 200}, (_, i) => i + 1).reverse(),

        client_id: '?client_id=' + process.env.VUE_APP_SC_CLIENT_ID,
        
      }
      
  },
  name: 'Home',

  methods: {
      play: function(song) {
        console.log('play triggered', song);
        // this.$store.commit("updateSongData", song);
        this.$store.dispatch('setSongData', song);

        // let s = this.$store.state;
        // setTimeout(function() { 
        //   console.log(s);
        // },5000);
      },
  },
  components: {
    // HelloWorld
    // Song
    Round,
    // VueSound
    AudioPlayer,
    LyricsSidebar
  },
  computed: {
      songData: function() {
        return this.$store.state.songData;
      },
      
  }
}
</script>

<style>
.container {
  width: 960px;
  max-width: 960px;
  @apply mx-auto pt-32;
}
.home {
  
}
</style>

